<template>
  <div>
    <div
      class="font-weight-bold mt-8 mb-2"
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? { 'font-size': '22px' }
          : { 'font-size': '31px' },
      ]"
    >
      Paquetes del proveedor
    </div>
    <ListCards :cards="packages" type="Package" class="mb-16" />
  </div>
</template>
<script>
import ListCards from "@/components/LandingPage/Shared/ListCards.vue";

export default {
  components: {
    ListCards,
  },
  props: {
    packages: { type: Array, required: true },
  },
};
</script>
