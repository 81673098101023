<template>
  <div>
    <div class="text-h3 text-md-h2 font-weight-bold my-8">
      Información del proveedor
    </div>

    <div class="my-8 my-md-12">
      <v-row align="center" class="justifty-start justify-md-center">
        <v-col
          class="text-left text-md-center py-1 py-md-auto"
          cols="12"
          md="4"
        >
          <span class="d-inline-flex font-weight-regular">
            <span class="text-h3 text-md-h2 font-weight-bold mr-2 mr-sm-5">
              {{ provider.service.length }}
            </span>
            <span class="text-body-1 text-sm-h4 align-self-center">
              {{
                provider.service.length === 1
                  ? "Servicio publicado"
                  : "Servicios publicados"
              }}
            </span>
          </span>
        </v-col>
        <v-col
          class="text-left text-md-center py-1 py-md-auto"
          cols="12"
          md="4"
        >
          <span class="d-inline-flex font-weight-regular">
            <span class="text-h3 text-md-h2 font-weight-bold mr-2 mr-sm-5">
              0
            </span>
            <span class="text-body-1 text-sm-h4 align-self-center">
              Veces contratado
            </span>
          </span>
        </v-col>
        <v-col
          class="text-left text-md-center py-1 py-md-auto"
          cols="12"
          md="4"
        >
          <span class="d-inline-flex font-weight-regular">
            <span class="text-h3 text-md-h2 font-weight-bold mr-2 mr-sm-5">
              {{
                provider.providerComment === null
                  ? 0
                  : provider.providerComment.length
              }}
            </span>
            <span class="text-body-1 text-sm-h4 align-self-center">
              {{ provider.providerComment.length === 1 ? "Reseña" : "Reseñas" }}
            </span>
          </span>
        </v-col>
      </v-row>
    </div>

    <ImageSlideshow :images="formatImagesUrl()" />

    <v-divider class="secondary"></v-divider>
    <div class="text-h3 text-md-h2 font-weight-bold my-8">
      Información general
    </div>
    <div class="text-body-1">{{ provider.description }}</div>

    <div v-if="provider.service.length > 0" class="my-16 text-body-1">
      <div class="my-5">
        <v-icon
          v-if="provider.service[0].allCities"
          class="mr-3 secondary--text"
          x-large
        >
          far fa-check-circle
        </v-icon>
        <v-icon v-else class="mr-3 secondary--text" x-large>
          far fa-times-circle
        </v-icon>
        <span v-if="provider.service[0].allCities">
          Trabaja en otras ciudades.
        </span>
        <span v-else>No trabaja en otras ciudades.</span>
      </div>

      <div class="my-5">
        <v-icon
          v-if="provider.service[0].partyPlanner"
          class="mr-3 secondary--text"
          x-large
        >
          far fa-check-circle
        </v-icon>
        <v-icon v-else class="mr-3 secondary--text" x-large>
          far fa-times-circle
        </v-icon>
        <span v-if="provider.service[0].partyPlanner">
          Trabaja con organizadores de evento.
        </span>
        <span v-else>No trabaja con organizadores de evento.</span>
      </div>

      <div class="my-5">
        <v-icon class="mr-3 secondary--text" x-large>
          far fa-check-circle
        </v-icon>
        <span>
          {{ provider.service.length }}
          {{
            provider.service.length === 1
              ? "servicio registrado."
              : "servicios registrados."
          }}
        </span>
      </div>
      <!-- <div class="my-5">
        <v-icon class="mr-3 secondary--text" x-large>
          far fa-check-circle
        </v-icon>
        <span>Perfil verificado.</span>
      </div> -->
    </div>
    <div v-else class="my-16">
      <div class="my-5">
        <v-icon class="mr-3 secondary--text" x-large>
          far fa-times-circle
        </v-icon>
        <span>0 servicios registrados.</span>
      </div>
    </div>

    <v-divider v-if="provider.service.length > 0" class="secondary"></v-divider>
    <v-row v-if="provider.service.length > 0" class="my-5">
      <!-- <v-col cols="12" md="8">
        <div class="d-flex mb-6">
          <v-avatar size="70">
            <img :src="provider.admin.img" :alt="provider.admin.name" />
          </v-avatar>
          <div>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-bold my-0"
                  :style="[
                    $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                      ? { 'font-size': '22px' }
                      : { 'font-size': '31px' },
                  ]"
                >
                  {{ provider.admin.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                  class="font-italic text-subtitle-1 grey--text text--darken-1 my-0"
                >
                  Administrador
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
        <div class="pr-md-12">{{ provider.admin.info }}</div>
      </v-col> -->
      <v-col cols="12" md="4">
        <div class="text-h3 text-md-h2 font-weight-bold my-8 my-sm-2 mb-sm-8">
          Categorías de servicios
        </div>
        <div
          v-for="(category, i) in getFilteredCategories"
          :key="i"
          class="my-5"
        >
          <span class="text-body-1">{{ category }} </span>
        </div>
      </v-col>
    </v-row>
    <!-- <div
      class="font-weight-bold text-center my-8"
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? { 'font-size': '22px' }
          : { 'font-size': '31px' },
      ]"
    >
      <span class="d-inline-block d-sm-inline-flex">
        <v-icon size="27" class="mr-5 secondary--text align-self-center">
          fa-star
        </v-icon>
        <span class="align-self-center">
          {{
            provider.providerComment[0].score == null
              ? 0
              : provider.providerComment.length
          }}
          - Evaluaciones recientes de
          {{ provider.name }}
        </span>
      </span>
    </div> -->
    <!--<v-container>
      <v-row justify="space-around">
        <v-col
          v-for="(review, i) in provider.reviewsToShow"
          :key="i"
          class="text-center"
          cols="12"
          md="2"
        >
          <span class="text-subtitle-1 font-weight-regular">
            <v-avatar size="80">
              <img :src="review.userPicture" :alt="review.userName" />
            </v-avatar>
            <div class="my-3">
              {{ review.userName }}
            </div>
            <div :title="review.text" class="font-italic line-clamp">
              {{ review.text }}
            </div>
          </span>
        </v-col>
      </v-row>
    </v-container>
    <div class="text-center mt-8 mb-16">
      <Button 
        text="Ver todas las reseñas"
        outlined
        class="mb-10"
        aria-label="Ver todas las reseñas"
        @event="handleModal('handleReviewsModal', $event)"
      />
    </div> -->
    <!-- <div class="text-center mt-16">
      ¿Algo no está bien con este perfil?
      <a
        class="text-decoration-underline font-weight-bold black--text"
        @click="handleReportModal"
        >Reportar perfil</a
      >
    </div> -->
    <!-- <ModalReport
      :dialog="reportModal"
      :motives="reportMotives"
      type="Provider"
      @closeModal="handleReportModal"
    /> -->
  </div>
</template>
<script>
import ImageSlideshow from "@/components/Shared/ImageSlideshow.vue";
// import Button from "@/components/Shared/Button.vue";
// import ModalReport from "@/components/Shared/Modal/ModalReport.vue";

export default {
  components: {
    ImageSlideshow,
    // Button,
    // ModalReport,
  },
  props: {
    provider: { type: Object, required: true },
    handleModal: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    // reportModal: false,
    // reportMotives: [
    //   {
    //     name: "Se hace pasar por otra persona",
    //     value: "falseImpersonation",
    //   },
    //   {
    //     name: "Su servicio no cumple con lo que ofrece",
    //     value: "poorService",
    //   },
    //   { name: "Otro motivo", value: "anotherMotive" },
    // ],
  }),
  computed: {
    getFilteredCategories() {
      let data = [];

      this.provider.service.filter((item, index) => {
        data.indexOf(item.category) === index ? null : data.push(item.category);
      });
      const arraytemp = new Set(data);

      return [...arraytemp];
    },
  },
  methods: {
    // handleReportModal() {
    //   this.reportModal = !this.reportModal;
    // },
    formatImagesUrl() {
      return this.provider.providerImage.map((image) => image.url);
    },
  },
};
</script>
<style scoped lang="scss">
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
